import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import ReactMarkdown from "react-markdown";

type CoachingMarkdownProps = {
  markdown: string;
} & BoxProps;

const CoachingMarkdown: React.FC<CoachingMarkdownProps> = ({
  markdown,
  ...boxProps
}) => {
  return (
    <Box
      mb={4}
      fontSize="sm"
      px={2}
      color="var(--chakra-colors-gray-700)"
      sx={{
        /* Apply margin-top to all headings */
        "& h1, & h2, & h3, & h4, & h5, & h6": {
          marginTop: "1rem",
          fontWeight: "600",
          mb: "0.25rem",
        },
        "& h1": {
          fontSize: "20.16px",
          marginBottom: "8px",
        },
        "& h2": {
          fontSize: "16.8px",
          marginTop: "32px",
          marginBottom: "16px",
        },
        "& h3": {
          fontSize: "var(--chakra-fontSizes-sm)",
          margin: "16px 0 8px",
        },
        "& h4, & h5, & h6": {
          fontSize: "11.67px",
          color: "var(--chakra-colors-gray-500)",
          margin: "12px 0 4px",
        },
        "& p": {
          margin: "0",
          mb: "0.5rem",
        },
        "& hr": {
          display: "block",
          height: "1px",
          background: "var(--chakra-colors-gray-200)",
          border: "0",
          margin: "16px 0",
        },
        "& li": {
          margin: "0",
          mb: "0.25rem",
        },
        "& ul, & ol": {
          marginLeft: "1rem",
        },
        "& a": {
          color: "var(--chakra-colors-blue-600)",
        },
      }}
      {...boxProps}
    >
      <ReactMarkdown
        components={{
          a(props) {
            const { node, ...rest } = props;
            const href = node.properties?.href || "";
            if (!(typeof href === "string")) {
              return null;
            }
            // Handle internal links to timestamps in the current interview
            if (href.startsWith(`?t=`)) {
              const seconds = parseInt(href.split("?t=")[1]) || 0;
              return (
                <a
                  onClick={(e) => {
                    e.preventDefault();

                    // Update URL to new timestamp, keeping old params
                    const currentParams = new URLSearchParams(
                      window.location.search
                    );
                    currentParams.set("t", seconds.toString());
                    const newUrl = `${
                      window.location.pathname
                    }?${currentParams.toString()}`;
                    window.history.replaceState({}, "", newUrl);
                  }}
                  {...rest}
                />
              );
            }

            // Otherwise link to new tab
            return <a target="_blank" {...rest} />;
          },
        }}
      >
        {markdown}
      </ReactMarkdown>
    </Box>
  );
};

export default CoachingMarkdown;
