import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Switch,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../../../components";
import CoachingPreviewModal from "./CoachingPreviewModal";
import CoachingUserSelect from "./CoachingUserSelect";
import { CoachingConfiguration } from "./types";
import { TaskRunResult } from "./useCoachingTask";

type CoachingSettingsFormProps = {
  configuration: CoachingConfiguration;
  taskRunResult: TaskRunResult;
  onPreview: (userId: string, config: CoachingConfiguration) => void;
  onSave: (config: CoachingConfiguration) => void;
  saveLoading?: boolean;
};

type FieldValues = {
  customInstructions: string;
  sendEmail: boolean;
};

const CoachingDemoForm: React.FC<CoachingSettingsFormProps> = ({
  configuration,
  taskRunResult,
  onPreview,
  onSave,
  saveLoading = false,
}) => {
  const [previewUserId, setPreviewUserId] = useState<string | undefined>(
    undefined
  );
  const previewModal = useDisclosure();

  const { register, handleSubmit, watch } = useForm<FieldValues>({
    defaultValues: {
      ...configuration,
    },
  });
  const watchValues = watch();

  const onPreviewClick = useCallback(() => {
    if (previewUserId) {
      previewModal.onOpen();
      onPreview(previewUserId, watchValues);
    }
  }, [previewUserId, onPreview, watchValues]);

  const saveChanges = handleSubmit((values) => {
    onSave(values);
  });

  return (
    <>
      <form onSubmit={saveChanges}>
        <Heading as="h3" size="sm" mb={2}>
          Delivery
        </Heading>
        <Text fontSize="sm" fontWeight="normal" color="gray.600" mb={2}>
          Control whether your interviewers receive tips.
        </Text>
        <FormControl display="flex" alignItems="center" mt="2">
          <Switch
            id="sendCoachingTips"
            defaultChecked={configuration.sendEmail}
            {...register("sendEmail")}
          />
          <FormLabel
            htmlFor="sendCoachingTips"
            mb="0"
            ml="3"
            color="gray.900"
            fontSize="sm"
          >
            Interview coaching tips will be sent to your interviewers every two
            weeks on Monday.
          </FormLabel>
        </FormControl>
        <Divider my={5} />
        <Heading as="h3" size="sm" mb={2}>
          Coaching Content
        </Heading>
        <Text fontSize="sm" fontWeight="normal" color="gray.600" mb={2}>
          The default metrics are Candidate Talk Ratio, Question Opportunity,
          Agenda Set and On-time Starts.
        </Text>
        <Text fontSize="sm" fontWeight="normal" color="gray.600" mb={2}>
          The default topics are: Follow-up Questions, Leading Questions,
          Question Delivery, Acknowledging Answers, and Representing The
          Company.
        </Text>

        <Heading as="h3" size="sm" mb={2} mt={4}>
          Customization instructions
        </Heading>

        <Textarea
          mb="4"
          minH="130px"
          fontSize="sm"
          placeholder="Describe any changes to the default format and content. For example,&#10;&#x2022;Skip the metrics, and show just one coaching topic. &#10;&#x2022;Add a coaching topic about how to sell Example Co. to candidates.&#10;&#x2022;Add a coaching topic about compliance with hiring practices in Example Country&#10;&#x2022;Ignore the defaults and only output a single paragraph of feedback to the interviewer."
          _placeholder={{ color: "gray.400", lineHeight: "20px" }}
          borderColor="gray.200"
          {...register("customInstructions")}
        />
        <Divider my={5} />
        <Heading as="h3" size="sm" mb={2}>
          Preview
        </Heading>
        <Text fontSize="sm" fontWeight="normal" color="gray.600" mb={2}>
          Test out what your coaching tips will look like for a specific user.
        </Text>
        <Flex direction="row" mt="4" mb="4">
          <CoachingUserSelect onChange={setPreviewUserId} />
          <Button
            variant="prismSecondary"
            size="md"
            ml="4"
            onClick={onPreviewClick}
            isDisabled={!previewUserId}
          >
            Preview
          </Button>
        </Flex>
        <Button
          variant="prismPrimary"
          mt="7"
          type="submit"
          isLoading={saveLoading}
          loadingText="Saving..."
        >
          Save changes
        </Button>
      </form>
      <CoachingPreviewModal
        isOpen={previewModal.isOpen}
        onClose={previewModal.onClose}
        taskRunResult={taskRunResult}
      />
    </>
  );
};

export default CoachingDemoForm;
